<script setup lang="ts">
import { ClientApiError } from '@shopware-pwa/types';
import { LoginForm, Modal, Input, Button, Title } from '@upa/design-system';
import { getCustomerLoginEndpoint } from '@shopware-pwa/api-client';
import { useGoogleReCaptcha } from '@/composables/useGoogleRecaptcha';
const config = useRuntimeConfig();
const emits = defineEmits<{
  (e: 'success'): void;
  (e: 'close'): void;
  (e: 'resetPassword', value: { email: string }): void;
}>();
// const router = useRouter();
const { isLoggedIn, login } = useUser();
const { t, localeProperties } = useI18n();
import { useVuelidate } from '@vuelidate/core';
import { email, required, helpers } from '@vuelidate/validators';
const { refreshSessionContext } = useSessionContext();
const { apiInstance } = useShopwareContext();
const { recaptcha, isRecaptchaActive } = useGoogleReCaptcha();

// const { mergeWishlistProducts } = useWishlist();
const { pushSuccess, pushError } = useNotifications();
// const localePath = useLocalePath();
// const { formatLink } = useInternationalization(localePath);
const loginErrors = ref<string[]>([]);

/**
 * select language
 */
const { languages, changeLanguage, replaceToDevStorefront } =
  useInternationalization();
const languageId = localeProperties.value.languageId;

const onChangeHandler = async (option: { id: string }) => {
  const data = await changeLanguage(option.id);
  if (data.redirectUrl) {
    window.location.replace(replaceToDevStorefront(data.redirectUrl));
  } else {
    window.location.reload();
  }
};

const parsedLanguages = computed(() =>
  languages.value.map((lang) => ({
    id: lang.id,
    name: lang.translationCode?.name as string,
  })),
);

// const goToRegister = () => {
//   emits('close');
//   router.push(formatLink('/register'));
// };

const formData = ref({
  username: '',
  password: '',
  remember: true,
  _grecaptcha_v3: '',
});

const invokeLogin = async (data: any): Promise<void> => {
  formData.value = {
    username: data.email,
    password: data.password,
    remember: true,
  };
  if (isRecaptchaActive()) {
    formData.value._grecaptcha_v3 = await recaptcha('login');
  }
  loginErrors.value = [];
  try {
    // TODO: remove this line once the https://github.com/shopware/frontends/issues/112 issue is fixed
    await refreshSessionContext();
    await apiInstance.invoke.post(getCustomerLoginEndpoint(), formData.value);
    emits('success');
    pushSuccess(t('login.notification.loggedInSuccess'));
    emits('close');
    // mergeWishlistProducts();
  } catch (error) {
    const e = error as ClientApiError;
    loginErrors.value = e.messages.map(({ detail }) => detail);
    pushError(t('login.apiLoginErrorMsg'));
  }
};

/**
 * password recovery
 */
const { resetPassword, errors } = useCustomerPassword();
// const { apiInstance } = useShopwareContext();
const isSuccess = ref(false);

const state = reactive({
  email: '',
  storefrontUrl: typeof window !== 'undefined' ? window.location.origin : '',
});

const rules = computed(() => ({
  email: {
    required: helpers.withMessage(
      t('login.resetModal.email.errors.required'),
      required,
    ),
    email: helpers.withMessage(
      t('login.resetModal.email.errors.formatRequired'),
      email,
    ),
  },
}));

const v$ = useVuelidate(rules, state);

const emailImputElement = ref();
useFocus(emailImputElement, { initialValue: true });

const isModalOpen = ref(false);
const isSuccessModalOpen = ref(false);

const openModal = () => {
  isModalOpen.value = true;
};
const closeModal = () => {
  isModalOpen.value = false;
  isApiError.value = false;
  state.email = '';
  v$.value.$reset();

  emits('close');
};

const recoverPasswordErrors = computed(() =>
  errors.resetPassword?.map(({ detail }) => detail).toString(),
);

const invokeRecover = async (): Promise<void> => {
  v$.value.$touch();
  if (v$.value.$invalid) {
    return;
  }

  try {
    const emailSent = await resetPassword(state);

    if (emailSent) {
      isApiError.value = false;
      isModalOpen.value = false;
      isSuccess.value = true;
      isSuccessModalOpen.value = true;
      // emits("success");
    } else {
      isApiError.value = true;
    }
  } catch (error) {
    console.error('error resend email', error);
  }
};

const isApiError = ref(false);
const change = () => {
  isApiError.value = false;
};
</script>

<template>
  <!-- <div
    class="min-h-full flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8"
  > -->
  <div>
    <div v-if="!isLoggedIn">
      <!-- <div>
        <img
          class="mx-auto h-12 w-auto"
          src="/logo.svg"
          alt="Logo"
        >
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
          {{ $t("account.signInLabel") }}
        </h2>
      </div> -->

      <!-- CUSTOM -->
      <LoginForm
        :languages="parsedLanguages"
        :selected-language-id="languageId"
        :reset-pw-btn-label="$t('login.resetPwBtnLabel')"
        :login-btn-label="$t('login.loginBtnLabel')"
        @submit="invokeLogin"
        @select-language="onChangeHandler"
        @reset-password="openModal"
      />
      <!-- <LanguageSwitcher /> -->
      <!-- CUSTOM END -->

      <!-- <form class="mt-8 space-y-6" @submit.prevent="invokeLogin"> -->
      <!-- <input
          v-model="formData.remember"
          type="hidden"
          name="remember"
          data-testid="login-remember-input"
        >
        <div class="rounded-md shadow-sm -space-y-px">
          <div>
            <label for="email-address" class="sr-only">{{
              $t("form.email")
            }}</label>
            <input
              id="email-address"
              ref="emailImputElement"
              v-model="formData.username"
              name="email"
              type="email"
              autocomplete="email"
              required
              class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              :placeholder="$t('form.email')"
              data-testid="login-email-input"
            >
          </div>
          <div>
            <label for="password" class="sr-only">{{
              $t("form.password")
            }}</label>
            <input
              id="password"
              v-model="formData.password"
              name="password"
              type="password"
              autocomplete="current-password"
              required
              class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              :placeholder="$t('form.password')"
              data-testid="login-password-input"
            >
          </div>
        </div> -->

      <!-- <slot :data="formData" /> -->

      <!-- <slot name="error">
          <div
            v-if="loginErrors.length"
            class="flex items-center justify-between"
            data-testid="login-errors-container"
          >
            <div class="flex items-center">
              <div
                class="login-errors text-red-600 focus:ring-indigo-500 border-gray-300 rounded"
              >
                {{ loginErrors }} 
              </div>
            </div>
          </div>
        </slot> -->

      <!-- <div>
          <button
            class="group relative w-full flex justify-center py-2 px-4 mb-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
            type="submit"
            data-testid="login-submit-button"
          >
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
              <div class="w-5 h-5 i-carbon-locked" />
            </span>
            {{ $t("account.signIn") }}
          </button>

          <slot name="action">
            <div class="w-full mt-4" @click="$emit('close')">
              <button
                type="button"
                class="group relative w-full flex justify-center py-2 px-4 mb-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                data-testid="login-sign-up-link"
                @click="goToRegister()"
              >
                {{ $t("account.signUp") }}
              </button>
            </div>
          </slot>
        </div> -->
      <!-- </form> -->
    </div>
    <div v-else>
      <!-- <h2>{{ $t("account.loggedInInfo") }}</h2>
      <button
        class="group relative w-full flex justify-center py-2 px-4 mb-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500"
        @click="$emit('close')"
      >
        close
      </button> -->

      <!-- TODO: replace with approved loading icon -->
      <div class="flex justify-center">
        <div role="status">
          <div
            class="h-15 w-15 i-carbon-progress-bar-round animate-spin c-gray-100 flex justify-center"
          />
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>

    <!-- password reset modal -->
    <Modal
      v-if="isModalOpen"
      show-layer
      centered
      @layer-click="closeModal"
      @escape-press="closeModal"
      @close="closeModal"
    >
      <template #content>
        <slot name="title">
          <Title
            level="h3"
            class="login-modal__title"
            :content="$t('login.resetPwBtnLabel')"
          />
        </slot>
        <slot name="content">
          <form
            novalidate
            class="login-modal__container"
            @submit.prevent="invokeRecover"
          >
            <div class="login-modal__content">
              <Input
                v-model="state.email"
                :label="$t('login.resetModal.email.label')"
                :error-text="v$.email.$errors?.[0]?.$message"
                @blur="v$.email.$touch()"
                @input="change"
              />
              <slot name="error">
                <div
                  v-if="recoverPasswordErrors.length && isApiError"
                  class="login-modal__api-error"
                >
                  <div>
                    <div class="login-modal__api-error-text">
                      {{ $t('login.resetModal.apiErrorMsg') }}
                    </div>
                  </div>
                </div>
              </slot>
            </div>

            <div class="login-modal__buttons">
              <Button type="submit" primary class="login-modal__button">
                {{ $t('login.resetModal.resetButtonLabel') }}
              </Button>
              <Button
                pure
                class="login-modal__button login-modal__button-pure"
                @click="closeModal"
              >
                {{ $t('login.resetModal.returnButtonLabel') }}
              </Button>
            </div>
          </form>
        </slot>
      </template>
    </Modal>

    <!-- reset success modal -->
    <Modal
      v-if="isSuccessModalOpen"
      show-layer
      centered
      class="login-success-modal"
      @layer-click="isSuccessModalOpen = false"
      @escape-press="isSuccessModalOpen = false"
      @close="isSuccessModalOpen = false"
    >
      <template #content>
        <slot name="title">
          <Title
            level="h3"
            class="login-modal__title"
            :content="$t('login.successModal.header')"
          />
        </slot>
        <slot name="content">
          <div class="login-modal__text">
            {{ $t('login.successModal.content') }}
          </div>
        </slot>
      </template>
    </Modal>
  </div>
</template>

<style lang="scss" scoped>
:deep(.select-option-list) {
  max-height: 240px;
  overflow: auto;
}

:deep(.modal__container) {
  max-width: 500px;

  .title {
    margin-bottom: 32px;
  }

  .login-modal__content {
    padding-bottom: 32px;
    margin-bottom: 32px;
    border-bottom: 1px solid var(--_c-gray-light-accent);
  }

  .login-modal__api-error {
    position: absolute;
    padding-top: 4px;
    font-size: 12px;
  }

  .login-modal__api-error-text {
    color: var(--c-primary);
  }

  .button__wrapper {
    .button--pure {
      text-transform: none;
      text-decoration: underline;
      font-size: 14px;
      color: var(--_c-black-secondary);
      line-height: 18px;
    }

    &:first-of-type {
      margin-bottom: 32px;
    }
  }

  .button {
    width: 100%;
  }
}
</style>
